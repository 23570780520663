import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store';
import { Box, Grid, Button } from '@mui/material';
import { fetchContentOfCourse, setSelectedVideoDetails } from '../../../../features/coursesSlice';
import MainObjectComponent from './MainObjectComponent';
import { createMonitoringUsageOfMovie } from '../../../../api/serverApis/monitoringApi';
import { setCurrentVideo } from '../../../../features/quizSlice';
import { getAllArticlesIdsAndTopicNumberByCourseIdSlice } from '../../../../features/flowsSlice';
import AssignmentIcon from '@mui/icons-material/Assignment';
interface Video {
  id: string;
  subject: string;
  summary: string;
  length: string;
  startTime: number;
  endTime: number;
}

interface MainVideo extends Video {
  subVideos: Video[];
}

interface TableOContentProps {
  theme: any;
  courseId: string;
  videoData: any;
  onMoviesLoaded: (movies: MainVideo[]) => void;
  finishedSubVideos: { [key: string]: string[] };
  onQuizButtonClick: (quizData: any, isOnlyArticle: boolean) => void;
  onVideoButtonClick: (videoUrl: string, videoData: any) => void;
  setCurrentlyPlayingSubVideoFromLayout?: (movieId: string, subVideoId: string) => void;
  getTopicsNumberByArticleId: (articleId: string) => void;
  onFlowButtonClick: (flowData: any) => void;
  handleAllQuestions: () => void;
}

const TableOContentComponent: React.FC<TableOContentProps> = ({
  theme,
  videoData,
  courseId,
  onMoviesLoaded,
  finishedSubVideos,
  onQuizButtonClick,
  onVideoButtonClick,
  setCurrentlyPlayingSubVideoFromLayout,
  getTopicsNumberByArticleId,
  onFlowButtonClick,
  handleAllQuestions
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const contentOfCourse = useSelector((state: RootState) => state.courses.contentOfCourse);
  const [expandedSummaries, setExpandedSummaries] = useState<{ [key: string]: boolean }>({});
  //const { videoProgress, currentlyPlayingSubVideo, setCurrentlyPlayingSubVideo } = useVideoProgress();
  const [selectedQuizVideo, setSelectedQuizVideo] = useState<string | null>(null);
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);

  const activeVideoRef = useRef<HTMLDivElement | null>(null);
  const topicsNumber = useSelector((state: RootState) => state.quiz.topicsNumber);
  const flowArticleAndTopic = useSelector((state: RootState) => state.flows.flowsArticleAndTopic);
  const [selectedQuizSubVideoId, setSelectedQuizSubVideoId] = useState<string | null>(null);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchContentOfCourse(courseId));
      getTopicsNumberByArticleId(courseId);
      dispatch(getAllArticlesIdsAndTopicNumberByCourseIdSlice({ courseId }));
    }
  }, [courseId]);

  useEffect(() => {
    if (contentOfCourse) {
      const sortedContentOfCourse = contentOfCourse
        ? [...contentOfCourse]
          .filter((item) => item.type === 'video') // Filter items with type 'video'
          .sort((a, b) => a.subject.localeCompare(b.subject)) // Sort by subject
        : [];
      const videos = sortedContentOfCourse.flatMap((mainVideo: any) =>
        mainVideo.content.map((subVideo: any) => ({
          ...subVideo,
        }))
      );
      onMoviesLoaded(videos);
    }
  }, [contentOfCourse]);

  // useEffect(() => {
  //   if (currentlyPlayingSubVideo) {
  //     setExpandedAccordion(currentlyPlayingSubVideo.movieId);
  //     if (activeVideoRef.current) {
  //       activeVideoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //     }
  //   }
  // }, [currentlyPlayingSubVideo]);

  const handleVideoSelect = (subVideo: any) => {
    setSelectedQuizVideo(null);
    onVideoButtonClick(subVideo.url, subVideo);
  };

  const handleSubVideoSelect = (subVideo: any, movieId: string, id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    handleVideoSelect(subVideo);
    createMonitoringUsageOfMovie(movieId, courseId, subVideo.id, subVideo.length ? "movie" : "pdf", subVideo.startTime ? subVideo.startTime : '', subVideo.endTime ? subVideo.endTime : null, subVideo.subject, subVideo.subSubject, subVideo.length ? subVideo.length : null, subVideo.startPageNumber ? subVideo.startPageNumber : null, subVideo.endPageNumber ? subVideo.endPageNumber : null);
    dispatch(setSelectedVideoDetails({ movieId, id, courseId: courseId ? courseId : "" }));
  };

  const handleToggleSummary = (id: string) => {
    setExpandedSummaries((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleQuizButtonClick = (quizData: any, movieId: string, id: string, event: React.MouseEvent, isOnlyArticle?: boolean) => {
    event.stopPropagation();
    dispatch(setSelectedVideoDetails({ movieId, id, courseId: courseId ? courseId : "" }));
    //setCurrentlyPlayingSubVideo({ movieId: movieId, subVideoId: id });
    onQuizButtonClick(quizData, isOnlyArticle ? true : false);
    //setSelectedQuizSubVideoId(id);
    dispatch(setCurrentVideo({ movieId: movieId, subVideoId: id }));
    setSelectedQuizVideo(id);

  };

  const handleFlowButtonClick = (flowData: any, movieId: string, id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    onFlowButtonClick(flowData);
    dispatch(setCurrentVideo({ movieId: movieId, subVideoId: id }));
  }
  // const calculateMainMovieProgress = (mainVideo: any) => {
  //   const subVideos = mainVideo.content;
  //   const totalProgress = subVideos.reduce((acc: number, subVideo: any) => {
  //     const subVideoProgress = videoProgress[mainVideo.movieId]?.[subVideo.id] || 0;
  //     return acc + subVideoProgress;
  //   }, 0);
  //   return subVideos.length ? totalProgress / subVideos.length : 0;
  // };
  const formatTimeOrPagesMainVideo = (mainVideo: any) => {
    if (mainVideo.length) {
      const math = Math.ceil(mainVideo.length / 60)

      let min = "";
      if (math <= 9) {
        min = "0" + math;
      }
      if (math > 9) {
        min = math.toString();
      }
      if (math === 0 || Number.isNaN(math)) {
        min = "  ";
      }
      if (isHebrew(mainVideo.subject)) {
        return `${min}`;
      }
      if (!isHebrew(mainVideo.subject)) {
        return `${min}min`;
      }
      return min.toString();
    }
    if (mainVideo.content[mainVideo.content.length - 1] && mainVideo.content[mainVideo.content.length - 1].endPageNumber) {
      return mainVideo.content[mainVideo.content.length - 1].endPageNumber;
    }
    return '';
  };
  const formatTimeOrPages = (subVideo: any) => {
    if (subVideo.startTime && subVideo.endTime) {
      const calcMin = parseInt(subVideo.endTime) - parseInt(subVideo.startTime);
      const math = Math.ceil(calcMin / 60);
      let min = "";
      if (math <= 9) {
        min = "0" + math;
      }
      if (math > 9) {
        min = math.toString();
      }
      if (isHebrew(subVideo.summary)) {
        return `${min}`;
      }
      if (!isHebrew(subVideo.summary)) {
        return `${min}min`;
      }
      return min.toString();
    }
    else if (subVideo.startPageNumber && subVideo.endPageNumber) {
      const startToEnd = `${subVideo.startPageNumber} - ${subVideo.endPageNumber}`;
      const numberOfPages = subVideo.endPageNumber - subVideo.startPageNumber;
      return `pages from to:  ${startToEnd} | number of pages: ${numberOfPages + 1}`;
    }
    return '';
  };
  const toggleAccordion = (movieId: string) => {
    setExpandedAccordions((prevExpanded) =>
      prevExpanded.includes(movieId)
        ? prevExpanded.filter((id) => id !== movieId)
        : [...prevExpanded, movieId]
    );
  };

  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);
  const sortedContentOfCourse = contentOfCourse ? [...contentOfCourse].sort((a, b) => a.subject.localeCompare(b.subject)) : [];
  return (
    <Box sx={{ flexGrow: 1, p: 1, marginTop: '4%', width: '92%', marginBottom: '20%' }}>
      <Button
        onClick={handleAllQuestions}
        variant="contained"
        startIcon={<AssignmentIcon />}
        sx={{
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          padding: '12px 30px',
          fontSize: '14px',
          textTransform: 'none',
          border: `1px solid ${theme.palette.common.black}`,  // Adding a black border for definition
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: theme.palette.grey[200],  // Slight gray for hover effect
            color: theme.palette.common.black,
          },
          '&:active': {
            transform: 'scale(0.98)',
          }
        }}
      >
        Start Exam
      </Button>
      <Grid container spacing={0.1} sx={{ marginTop: '2%' }}>
        {sortedContentOfCourse?.map((mainVideo, index) => (
          <MainObjectComponent
            key={mainVideo.movieId}
            mainVideo={mainVideo}
            index={index}
            expandedAccordions={expandedAccordions}
            toggleAccordion={toggleAccordion}
            courseId={courseId}
            videoData={videoData}

            handleSubVideoSelect={handleSubVideoSelect}
            formatTimeOrPages={formatTimeOrPages}
            formatTimeOrPagesMainVideo={formatTimeOrPagesMainVideo}
            finishedSubVideos={finishedSubVideos}

            handleVideoSelect={handleVideoSelect}
            handleQuizButtonClick={handleQuizButtonClick}
            handleFlowButtonClick={handleFlowButtonClick}
            selectedQuizSubVideoId={selectedQuizSubVideoId}
            topicsNumber={topicsNumber}
            flowArticleAndTopic={flowArticleAndTopic}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default TableOContentComponent;
