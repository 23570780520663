import React, { useRef, useEffect, useState, forwardRef } from 'react';
import { Index, Semantic } from './configuration/Interface';
import { Box, Typography, Divider, IconButton, Card } from '@mui/material';
import SearchBox from './SearchBox';
import CloseIcon from '@mui/icons-material/Close';
import ClickableTimes from './ClickableTimes';
import { secondsToMinutes, createYoutubeLinkWithTime, cleanTheDot } from '../searchComponents/UtilityFunctions'; // Ensure the correct import path
import InnerSearchResults from './InnerSearchResults';
import { RootState, AppDispatch } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBlob, selectUrlByBlob } from '../../features/blobFilesSlice';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import { Rnd } from 'react-rnd';
import YouTubeComponent from './mainComponenets/YouTubeComponent';
import { getVideoFiles, getPdfFiles } from '../../api/serverApis/filesApi';
import ReactPlayerComponent from './videoPlayer/ReactPlayerComponent';
import ReactStreamingComponent from './videoPlayer/ReactStreamingComponent';
import zIndex from '@mui/material/styles/zIndex';
import PDFViewer from '../generalComponents/PDFViewer';
import { useTheme } from '@mui/material/styles';
import { createMonitoringReferenceUsage } from '../../api/serverApis/monitoringApi';
import ReactDOM from 'react-dom';

interface VideoBoxInteractProps {
  index: Index | Semantic | any;
  onClose: () => void;
  xBox?: number;
  yBox?: number;
  widthBox?: number;
  heightBox?: number
  showTitle?: boolean;
  zIndex?: number;
}

const VideoBoxInteract = forwardRef<HTMLDivElement, VideoBoxInteractProps>(({ index, onClose, xBox, yBox, widthBox, heightBox, showTitle, zIndex }, ref) => {
  const [iframeUrl, setIframeUrl] = useState('');
  const boxRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(400);
  const [contentType, setContentType] = useState<string>('');
  const [mp4Url, setMp4Url] = useState<string | null>(null);
  const [showFullText, setShowFullText] = useState(false);
  const localUrl = useSelector((state: RootState) => selectUrlByBlob(state, index.movieLink));
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const videoUrl = index.movieLink;
  const hasCalledMonitoringRef = useRef(false);
  const [playerSize, setPlayerSize] = useState({ width: widthBox, height: heightBox });
  const [position, setPosition] = useState({ x: xBox, y: yBox });
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    const determineContentType = (url: string) => {
      if (!url) return 'website';
      if (url.includes('youtube.com') || url.includes('youtu.be')) {
        return 'youtube';
      } else if (url.includes('vimeo')) {
        return 'youtube';
      } else if (url.includes('.mp3')) {
        return 'mp3';
      } else if (url.includes('.opus')) {
        return 'mp3';
      } else if (url.includes('.pdf')) {
        return 'imbedPdf';
      } else if (url.includes('.mp4')) {
        return 'mp4';
      } else {
        return 'website';
      }
    };
    if (index.courseId && index.articleId && !hasCalledMonitoringRef.current) {
      createMonitoringReferenceUsage(index.courseId, index.articleId, index.pageNumber === "-1" ? "video" : "pdf", index.startTime, index.endTime, index.frameTime, index.pageNumber, index.score);
    }
    hasCalledMonitoringRef.current = true;
    const type = determineContentType(videoUrl);
    setContentType(type);
    if (type === 'mp4' || type === 'imbedPdf' || type === 'mp3') {
      if (!localUrl) {
        dispatch(fetchBlob(videoUrl));
      } else {
        setContentType(determineContentType(videoUrl));
      }
    }
  }, [videoUrl, localUrl, dispatch]);

  const renderContent = () => {
    let content;

    switch (contentType) {
      case 'imbedPdf':
        content = (localUrl && <PDFViewer fileUrl={localUrl} startPage={index.pageNumber ? index.pageNumber : index.startTime} endPage={Number.parseInt(index.pageNumber ? index.pageNumber : index.startTime) + 3} isPopup={true} pdfText={index.pdfText} />);
        break;
      case 'pdf':
        if (index.startTime) {
          content = (
            <iframe
              key={iframeUrl}
              src={iframeUrl}
              onLoad={() => console.log('Iframe loaded successfully')}
              onError={() => console.log('Error loading the iframe')}
              frameBorder="0"
              allowFullScreen
              style={{ width: '100%', height: '100%' }}
            ></iframe>
          );
        }
        break;
      case 'youtube':
        content = (<ReactPlayerComponent videoUrl={videoUrl} startTime={index.startTime} frameTime={index.frameTime} endTime={index.endTime} onTakeTest={() => { }} movieList={[]} />);
        break;
      case 'mp4':
        content = videoUrl && (
          <ReactStreamingComponent
            key={videoUrl}
            videoUrl={videoUrl}
            startTime={index.startTime}
            frameTime={index.frameTime}
            endTime={index.endTime}
            onTakeTest={() => { }}
            movieList={[]}
            videoData={index}

          />)
        break;
      case 'mp3':
        content = localUrl && (<ReactPlayerComponent videoUrl={localUrl} startTime={index.startTime} frameTime={index.frameTime} endTime={index.endTime} onTakeTest={() => { }} movieList={[]} />);
        break;
      case 'website':
        content = (
          <iframe
            src={videoUrl}
            frameBorder="0"
            allowFullScreen
            style={{ width: '100%', height: '100%' }}
          ></iframe>
        );
        break;
      default:
        content = <Typography>Unsupported media type</Typography>;
    }

    return content;
  };

  const boxStyle = {
    cursor: 'move',
    width: '50%',
    height: '90%',
    backgroundColor: 'background.paper',
    overflow: contentType === 'pdf' ? 'auto' : 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: '10rem',
    top: '3rem',
    borderRadius: '1rem',
    zIndex: zIndex ? zIndex : 1000
  };

  const iframeBoxStyle = {
    width: '100%',
    height: '100%',
    margin: '1rem',
    borderRadius: '1rem',
  };

  const textStyle = {
    flex: 1,
    overflowY: 'auto',
    padding: '1rem'
  };

  const handleResize = (e: any) => {
    if (!boxRef.current) return;
    const nextWidth = e.clientX - boxRef.current.getBoundingClientRect().left;
    const nextHeight = e.clientY - boxRef.current.getBoundingClientRect().top;
    if (nextWidth > 300 && nextHeight > 200) {
      setWidth(nextWidth);
      setHeight(nextHeight);
    }
  };

  return ReactDOM.createPortal(

    <Rnd
      size={{ width: playerSize.width || 400, height: playerSize.height || 300 }}
      position={{ x: position.x || 50, y: position.y || 50 }}
      onDragStop={(e, d) => setPosition({ x: d.x, y: d.y })}
      onResizeStop={(e, direction, ref, delta, pos) => {
        setPlayerSize({
          width: parseInt(ref.style.width, 10),
          height: parseInt(ref.style.height, 10),
        });
        setPosition(pos);
      }}
      bounds="window"
      style={{
        zIndex: 2147489,
        position: 'fixed', // Ensure it's always on top
      }}
    >
      <Card sx={{ width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', borderRadius: '1rem', backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#f5f5f5', border: '10px solid black' }}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: -7, right: -7, }}>
          <CloseIcon />
        </IconButton>
        {/* {!showTitle ? (
          <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#e0e0e0', fontSize: '14px', width: '90%', borderRadius: '0.5rem', marginLeft: '20px', marginTop: '10px' }}>
            <Typography className="drag-handle" sx={{ cursor: 'move', flex: 1, whiteSpace: showFullText ? 'normal' : 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {index.title}: {index.summaryText}
            </Typography>
            {!showFullText && (
              <Typography sx={{ cursor: 'pointer', marginLeft: '0.5rem', color: 'blue' }} onClick={() => setShowFullText(true)}>
                more...
              </Typography>
            )}
          </Box>
        ) : (
          <Box sx={{ height: '40px' }}>Move Box</Box>
        )} */}
        <Box sx={{ height: '4%', backgroundColor: theme.palette.background.paper }}></Box>
        <Box
          sx={{
            width: '100%',
            height: '100%', // The movie takes 80% of the height
            overflow: 'auto',
            position: 'relative',
            backgroundColor: 'black', // Optional: Make the video section stand out
          }}
        >
          {renderContent()}
        </Box>
        <Box sx={{ height: '10%', backgroundColor: theme.palette.background.paper }}></Box>
      </Card>
    </Rnd>,
    document.body
  );
});

export default VideoBoxInteract;