import React, { useState, useRef, useCallback } from 'react';
import { Paper, useTheme, Grid, Accordion, AccordionSummary, AccordionDetails, Box, Typography, IconButton, Tooltip, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { VariableSizeList as List, ListChildComponentProps } from 'react-window';
import SubObjectComponent from './SubObjectComponent'; // Import SubObjectComponent
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { createMonitoringUserUsageOpenSummary } from '../../../../api/serverApis/monitoringApi';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SyncIcon from '@mui/icons-material/Sync';
interface MainObjectComponentProps {
  videoData: any;
  mainVideo: any;
  expandedAccordions: string[];
  toggleAccordion: (movieId: string) => void;
  handleSubVideoSelect: (subVideo: any, movieId: string, subVideoId: string, e: any) => void;
  formatTimeOrPages: (subVideo: any) => string;
  formatTimeOrPagesMainVideo: (mainVideo: any) => string;
  finishedSubVideos: { [key: string]: string[] };
  handleVideoSelect: (subVideo: any) => void;
  handleQuizButtonClick: (quizData: any, movieId: string, id: string, e: any, isOnlyArticle?: boolean) => void;
  handleFlowButtonClick: (flowData: any, movieId: string, id: string, e: any) => void;
  selectedQuizSubVideoId: string | null;
  index: number;
  courseId: string;
  topicsNumber: any;
  flowArticleAndTopic: any;
}

const MainObjectComponent: React.FC<MainObjectComponentProps> = ({
  videoData,
  mainVideo,
  expandedAccordions,
  toggleAccordion,
  handleSubVideoSelect,
  formatTimeOrPages,
  finishedSubVideos,
  handleVideoSelect,
  handleQuizButtonClick,
  handleFlowButtonClick,
  selectedQuizSubVideoId,
  formatTimeOrPagesMainVideo,
  index,
  courseId,
  topicsNumber,
  flowArticleAndTopic,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [itemHeights, setItemHeights] = useState<{ [key: number]: number }>({});
  const [expandedItems, setExpandedItems] = useState<{ [key: number]: boolean }>({});
  const [selectedSubVideoId, setSelectedSubVideoId] = useState<string | null>(null);
  const currentVideo = useSelector((state: RootState) => state.playerToc.currentVideo);
  const [summaryExpanded, setSummaryExpanded] = useState<boolean>(false);
  const [nextSetOfQuestion, setNextSetOfQuestion] = useState<boolean>(false);
  const theme = useTheme();
  const listRef = useRef<any>(null);
  const permissions = JSON.parse(localStorage.getItem('permissions') || '{}');
  const canAccessQuiz = permissions?.TableOfContent?.seeQuiz?.canAccess;

  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);

    if (!expanded && !expandedAccordions.includes(mainVideo.movieId)) {
      //toggleAccordion(mainVideo.movieId);
      createMonitoringUserUsageOpenSummary(mainVideo.movieId, "");
    }
  };
  const toggleSummaryExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSummaryExpanded(!summaryExpanded);
    if (!summaryExpanded) {
      createMonitoringUserUsageOpenSummary(mainVideo.movieId, "");
    }
  };
  const toggleMainExpand = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
    toggleAccordion(mainVideo.movieId);
  };

  // const durationMinutes = `${Math.ceil(mainVideo.length / 60)}min`;
  // const durationMinutesHebrew = `דק${Math.ceil(mainVideo.length / 60)}`;
  // const itemSize = 75;

  //const listHeight = Math.min(mainVideo.content.length * itemSize, 600);

  const calculateSummaryRows = (summary: string) => {
    const charsPerRow = 62; // Assuming each row can contain approximately 62 characters
    const rowCount = Math.ceil(summary.length / charsPerRow);
    return rowCount;
  };

  const onExpandToggle = (index: number, isExpanded: boolean) => {
    const baseHeight = 75; // Base height for items without expansion
    const additionalHeightPerRow = 44; // Height to add per row of summary

    const summary = mainVideo.content[index].summary || '';
    const rowCount = calculateSummaryRows(summary);
    const subSubject = mainVideo.content[index].subSubject ? mainVideo.content[index].subSubject : '';
    const rowSubSubject = calculateSummaryRows(subSubject);

    setItemHeights((prev) => ({
      ...prev,
      [index]: isExpanded
        ? (rowCount + rowSubSubject) * additionalHeightPerRow + (isHebrew(mainVideo.content[index].subject) ? 150 : baseHeight)
        : getCharacterBasedHeight(mainVideo.content[index].subject),
    }));

    setExpandedItems((prev) => ({
      ...prev,
      [index]: isExpanded,
    }));

    if (listRef.current) {
      listRef.current.resetAfterIndex(index);
    }
  };

  const getCharacterBasedHeight = (text: string) => {
    return text.length > 42 ? 74 : 64;
  };
  const getCharacterBasedHeightMain = (text: string) => {
    return text.length;
  }
  const totalListHeight = Math.min(
    1300,
    (Array.isArray(mainVideo.content)
      ? mainVideo.content.reduce((total: number, subVideo: any) => {
        return total + getCharacterBasedHeight(subVideo.subject);
      }, 0)
      : 0) + 3
  );

  const getItemSize = (index: number) => {
    return itemHeights[index] || getCharacterBasedHeight(mainVideo.content[index].subject);
  };

  const handleSubVideoClick = (subVideoId: string) => {
    setSelectedSubVideoId(subVideoId); // Set the clicked sub-video as selected
  };

  const isSubVideoSelected = (subVideoId: string) => {
    return currentVideo?.movieId === mainVideo.movieId && currentVideo?.subVideoId === subVideoId;
  };
  const isPdfPageSelected = (pageNumber: number) => {
    return currentVideo?.pageNumber === pageNumber;
  };

  /**for the quiz choose */
  const isThereAQuiz = (subVideoId: string) => {
    if (topicsNumber && topicsNumber[mainVideo.movieId] && topicsNumber[mainVideo.movieId][subVideoId]) {
      return true;
    }
    return false;
  }
  const isThereAQuizForArticle = (subVideoId: string) => {
    if (topicsNumber && topicsNumber[mainVideo.movieId] && topicsNumber[mainVideo.movieId][-100] === 1) {
      return true;
    }
    return false;
  }
  //flows
  const isThereAFlowForArticle = (subVideoId: string) => {
    if (flowArticleAndTopic && flowArticleAndTopic[mainVideo.movieId] && flowArticleAndTopic[mainVideo.movieId][0] === 1) {
      return true;
    }
    return false;
  }
  // callback function for the list component
  const Row = useCallback(({ index, style }: any) => {
    const subVideo = mainVideo.content[index];
    const adjustedStyle = { ...style, height: getItemSize(index) };

    return (
      <div style={adjustedStyle} key={subVideo.id}>
        <SubObjectComponent
          index={index}
          mainVideo={mainVideo}
          subVideo={subVideo}

          handleSubVideoSelect={handleSubVideoSelect}
          formatTimeOrPages={formatTimeOrPages}
          finishedSubVideos={finishedSubVideos}
          handleVideoSelect={handleVideoSelect}
          handleQuizButtonClick={handleQuizButtonClick}
          selectedQuizSubVideoId={selectedQuizSubVideoId === subVideo.id}
          onExpandToggle={onExpandToggle}
          isExpanded={expandedItems[index] || false}
          //selectedSubVideoId={selectedSubVideoId === subVideo.id} // Pass if this sub-video is selected
          selectedSubVideoId={isSubVideoSelected(subVideo.id)} // Pass if this sub-video is selected
          handleSubVideoClick={handleSubVideoClick}
          courseId={courseId}
          videoData={videoData}
          isThereAQuiz={isThereAQuiz}
        />
      </div>
    );
  }, [expandedItems, getItemSize, selectedSubVideoId]);

  return (
    <Grid item xs={12} key={mainVideo.movieId}>
      <Accordion
        expanded={expandedAccordions.includes(mainVideo.movieId)}
        onChange={toggleMainExpand}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            direction: isHebrew(mainVideo.subject) ? 'rtl' : 'ltr'
          }}
        >
          <Box
            sx={{
              width: '100%',
              p: 0,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              direction: isHebrew(mainVideo.subject) ? 'rtl' : 'ltr'
            }}
          >
            {mainVideo.length ? (
              <VideoLibraryIcon color="action" /> // Show video icon if it's a video
            ) : (
              <PictureAsPdfIcon color="action" /> // Show PDF icon if it's a PDF
            )}
            <Box sx={{ ml: 1, flex: 1 }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', direction: isHebrew(mainVideo.subject) ? 'rtl' : 'ltr', marginRight: isHebrew(mainVideo.subject) ? '10px' : '', marginLeft: isHebrew(mainVideo.subject) ? '' : '' }} variant="body1">
                {/* {isHebrew(mainVideo.subject) ? `חלק ${index + 1}: ${mainVideo.subject}` : `Section ${index + 1}: ${mainVideo.subject}`} */}
                <span style={{}}>{mainVideo.subject}</span>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', direction: isHebrew(mainVideo.subject) ? 'rtl' : 'ltr' }}>
              {mainVideo.length ? (
                <>
                  <AccessTimeIcon sx={{ fontSize: '15px', marginRight: '5px', marginLeft: '5px' }} />
                  <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }} variant="body2" color="text.secondary">
                    {formatTimeOrPagesMainVideo(mainVideo)} {isHebrew(mainVideo.subject) ? 'דק\'' : ''}
                  </Typography>
                </>
              ) : (
                <>
                  <DescriptionIcon sx={{ fontSize: '15px', marginRight: '5px', marginLeft: '5px', }} />
                  <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }} variant="body2" color="text.secondary">
                    {formatTimeOrPagesMainVideo(mainVideo)} {isHebrew(mainVideo.subject) ? 'עמ\'' : 'pages'}
                  </Typography>
                </>
              )}
            </Box>
            <Tooltip title={summaryExpanded ? "Hide Summary" : "Show Summary"}>
              <IconButton sx={{ padding: 0.5, color: 'black' }} onClick={toggleSummaryExpand}>
                {summaryExpanded ? <RemoveIcon /> : <AddIcon />}
              </IconButton>
            </Tooltip>
            <span style={{ marginLeft: '-3px' }}></span>
            {mainVideo.length ?
              <Tooltip title={"play all movie"}>
                <IconButton sx={{ padding: 0.5, color: 'black' }} onClick={(e) => handleSubVideoSelect("", mainVideo.movieId, "", e)}>
                  <PlayArrowIcon />
                </IconButton>
              </Tooltip> : ''}
            <span style={{ marginLeft: '-3px' }}></span>
            {isThereAQuizForArticle(mainVideo) && <Tooltip title="Take Quiz">
              <IconButton sx={{ padding: 0.5, color: 'black' }} onClick={(e) => { e.stopPropagation(); handleQuizButtonClick({ articleId: mainVideo.movieId, topicNumber: '' }, mainVideo.movieId, '', e, true); }}>
                <AssignmentIcon />
              </IconButton>
            </Tooltip>}
            {isThereAFlowForArticle(mainVideo) && <Tooltip title="Show Flow">
              <IconButton sx={{ padding: 0.5, color: 'black' }} onClick={(e) => { e.stopPropagation(); handleFlowButtonClick({ articleId: mainVideo.movieId, topicNumber: 0 }, mainVideo.movieId, '0', e); }}>
                <SyncIcon />
              </IconButton>
            </Tooltip>}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {summaryExpanded && (
            <Box sx={{ width: '100%', mb: 2 }}>
              <Paper sx={{ p: 2, border: '1px solid gray', borderRadius: '4px' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1, direction: isHebrew(mainVideo.subject) ? 'rtl' : 'ltr' }}>
                  {isHebrew(mainVideo.subject) ? 'סיכום' : 'Summary'}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '14px', lineHeight: 1.5, direction: isHebrew(mainVideo.summary) ? 'rtl' : 'ltr' }}>
                  {mainVideo.summary}
                </Typography>
              </Paper>
            </Box>
          )}
          {expanded && (
            <Box sx={{ width: '100%' }}>
              <List
                height={totalListHeight} // Dynamic height based on content
                itemCount={mainVideo.content.length}
                itemSize={getItemSize} // Estimated average size
                width="100%"
                ref={listRef}
              >
                {Row}
              </List>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      {!expanded && summaryExpanded && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <Paper sx={{ p: 2, border: '1px solid gray', borderRadius: '4px' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1, direction: isHebrew(mainVideo.subject) ? 'rtl' : 'ltr' }}>
              {isHebrew(mainVideo.subject) ? 'סיכום' : 'Summary'}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '14px', lineHeight: 1.5, direction: isHebrew(mainVideo.summary) ? 'rtl' : 'ltr' }}>
              {mainVideo.summary}
            </Typography>
          </Paper>
        </Box>
      )}
    </Grid>
  );
};

export default MainObjectComponent;
