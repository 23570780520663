import React, { useState, useEffect, useRef } from 'react';
import VideoSemantic from '../VideoSemantic';
import VideoAnswer from '../VideoAnswer';
import VideoIndex from '../VideoIndex';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import { fetchSearchDeleteMovie } from '../../../features/combinationSearchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';
import { InputAdornment, TextField, Select, MenuItem, Grid, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip, IconButton, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Button, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Index, Semantic } from '../configuration/Interface';
import DynamicTable from '../../generalComponents/DynamicTable';
import VideoText from '../VideoText';
import LoadingDots from './LoadingDots';
import { createMonitoringOpenQuestion, createMonitoringDeleteAllQuestion, getMonitoringMaxByCourseIdAndDomain, getMonitoringUserCourseDomainMaxQuestion, resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay, createMonitoringLikedDislikedAllQuestion } from '../../../api/serverApis/monitoringApi';
import { addToGlobalFAQ, addToPrivateFAQ, removeFromGlobalFAQ, removeFromPrivateFAQ } from '../../../api/serverApis/combinationSearchApi';
import { getAllGlobalFAQData, getSearchByCourseId } from '../../../features/combinationSearchSlice';
import { getUsers } from '../../../api/serverApis/userApi';
import { SelectChangeEvent } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { addLikeToQuestion } from '../../../api/serverApis/combinationSearchApi';

interface User {
  userId: string;
  email: string;
}
interface MainSearchProps {
  data: any;
  theme: any;
  resultOfOpeningAskQuestion: any;
  handleMainSearchFullScreen: any;
  currentView: string;
  courseId: string;
}

const MainSearch: React.FC<MainSearchProps> = ({ courseId, handleMainSearchFullScreen, currentView, data, theme, resultOfOpeningAskQuestion }) => {
  const [openIndexes, setOpenIndexes] = useState<string[]>([]);
  const [openSemantics, setOpenSemantics] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string | false>(data && data.answerData && data.answerData.length > 0 ? `panel0` : false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<any | null>(null);
  const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({}); // Track loading state for each item
  const [fullScreenItem, setFullScreenItem] = useState<any>(null);
  const lightColors = ['white', '#F0F8FF']; // Softer shades for light theme
  const darkColors = ['black', '#353935']; // Deeper shades for dark theme
  const colors = theme.palette.mode === 'dark' ? darkColors : lightColors;
  const dispatch = useDispatch<AppDispatch>();
  const accordionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [prevAnswerDataLength, setPrevAnswerDataLength] = useState<number>(data?.answerData?.length || 0);
  const [maxQuestions, setMaxQuestions] = useState<number | null>(null);
  const [remainingQuestions, setRemainingQuestions] = useState<number | null>(null);
  const [starredQuestions, setStarredQuestions] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [differentUserId, setDifferentUserId] = useState('');
  const [permissions, setPermissions] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState(''); // For search input
  const [likeStatus, setLikeStatus] = useState<{ [key: string]: 'liked' | 'disliked' | null }>({});
  const domain = window.location.hostname;

  // Update filtered data when searchTerm changes
  useEffect(() => {
    setExpanded(false);
  }, [searchTerm, data]);

  useEffect(() => {
    const savedPermissions = localStorage.getItem('permissions');
    if (savedPermissions) {
      setPermissions(JSON.parse(savedPermissions));
    }
  }, []);

  useEffect(() => {
    const starred = Array.isArray(data?.answerData)
      ? data?.answerData.filter((item: any) => item.faqAddToGlobal === true).map((item: any) => item.answerDataId)
      : [];
    setStarredQuestions(starred);
  }, [data]);

  const scrollToAccordion = (panel: string) => {
    setTimeout(() => {
      const accordionElement = accordionRefs.current[panel];
      if (accordionElement) {
        // Scroll the accordion into view first
        accordionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

        // After the initial scroll completes, adjust the scroll position slightly
        // setTimeout(() => {
        //   window.scrollBy(0, -150); // Adjust this value (e.g., -50) to scroll higher or lower after the initial scroll
        // }, 500); // Ensure this delay gives enough time for the 'scrollIntoView' to complete
      }
    }, 100); // Initial timeout before triggering scroll
  };
  const scrollToOriginalPosition = (currentScrollPosition: any) => {
    setTimeout(() => {
      window.scrollTo({
        top: currentScrollPosition, // Preserve the initial scroll position
        behavior: 'smooth',
      });
    }, 100);
  }
  const scrollToFirstPanel = () => {
    const firstPanelElement = accordionRefs.current['panel0'];
    if (firstPanelElement) {
      firstPanelElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // Handle when new answerData is added
  useEffect(() => {
    const currentAnswerDataLength = data?.answerData?.length || 0;

    if (currentAnswerDataLength > prevAnswerDataLength) {
      // Close all accordions
      setSearchTerm('');
      setExpanded(false);
      setLoadingStates({});
      // Scroll to top
      scrollToTopOfPage();

      // Open the first accordion after a small delay (to ensure smooth scroll first)
      setTimeout(() => {
        setExpanded(`panel0`);
        scrollToAccordion('panel0');
      }, 500);
    }

    // Update the previous length with the current length
    setPrevAnswerDataLength(currentAnswerDataLength);
  }, [data?.answerData, prevAnswerDataLength]);

  // Utility function to check if an element is in the viewport
  const isElementInViewport = (element: HTMLElement | null) => {
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    const viewHeight = window.innerHeight || document.documentElement.clientHeight;
    const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    return (
      rect.top >= 0 && rect.left >= 0 && rect.bottom <= viewHeight && rect.right <= viewWidth
    );
  };


  useEffect(() => {
    if (courseId) {
      // Fetch the max questions allowed and remaining questions
      fetchMaxAndRemainingQuestions(courseId);
    }
  }, [data?.answerData]);

  const fetchMaxAndRemainingQuestions = async (courseId: string) => {
    try {
      await resetTheNumberAtTheBeginningOfAMonthAccordingToTheFirstDay(courseId);
      // Fetch max questions allowed for this course
      const maxQuestionsResponse = await getMonitoringMaxByCourseIdAndDomain(courseId);
      setMaxQuestions(maxQuestionsResponse?.data?.result || 0);

      // Fetch how many questions the user can still ask
      const remainingQuestionsResponse = await getMonitoringUserCourseDomainMaxQuestion(courseId);
      setRemainingQuestions(remainingQuestionsResponse?.data?.result || 0);
    } catch (error) {
      console.error('Error fetching max/remaining questions:', error);
    }
  };

  const goToFaqFullScreen = () => {
    if (currentView === 'search') {
      handleMainSearchFullScreen("video");
    }
    else {
      handleMainSearchFullScreen("search");
    }
  }
  const goAnalyzeContent = () => {
    if (currentView === 'analyze') {
      handleMainSearchFullScreen("video");
    }
    else {
      handleMainSearchFullScreen("analyze");
    }
  }
  const handleToggleIndex = (articleId: string) => {
    setOpenIndexes(prev => prev.includes(articleId) ? prev.filter(id => id !== articleId) : [...prev, articleId]);
  };

  const handleToggleSemantic = (articleId: string) => {
    setOpenSemantics(prev => prev.includes(articleId) ? prev.filter(id => id !== articleId) : [...prev, articleId]);
  };
  const scrollToTopOfPage = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const handleChange = (panel: string, item: any) => async (event: React.SyntheticEvent, isExpanded: boolean) => {
    const currentScrollPosition = window.scrollY;
    if (isExpanded && item.answer.answer === "Waiting for an answer...") {
      scrollToFirstPanel();
      setLoadingStates(prev => ({ [panel]: true })); // Set loading to true for the expanded panel
      await openingAskQuestion(item.searchId, item.answerDataId);
      scrollToFirstPanel();
      //setLoadingStates(prev => ({ ...prev, [panel]: false }));
    }
    setExpanded(isExpanded ? panel : false);
    setTimeout(async () => {
      if (isExpanded) {
        setLoadingStates(prev => ({ [panel]: true })); // Set loading to true for the expanded panel
        await openingAskQuestion(item.searchId, item.answerDataId);
        // Scroll to the top of the newly expanded accordion
        scrollToOriginalPosition(currentScrollPosition);
        scrollToAccordion(panel);

      }
    }, 100);
  };
  const handleChangeInFullScreen = async (panel: string, item: any) => {
    await openingAskQuestion(item.searchId, item.answerDataId);
    setLoadingStates(prev => ({ [panel]: true }));
    setFullScreenItem(item.answerDataId);
  }
  const handleMovieDelete = () => {
    const item = deleteConfirmOpen;
    dispatch(fetchSearchDeleteMovie({ courseId: data.courseId, searchId: data.searchId, answerDataId: item?.answerDataId }));
    createMonitoringDeleteAllQuestion(data.courseId, data.searchId, item.answerDataId, item.answer.question);
    setDeleteConfirmOpen(null);
  };
  /**todo:
   * make handleStarClick to be a under role of admin
   * make another one private or local to the user
   * as well foe the getAllUsers
   */
  useEffect(() => {
    // Retrieve permissions from localStorage
    const permissions = JSON.parse(localStorage.getItem('permissions') || '{}');

    // Check if the user has access to usersList in MainSearch
    const canAccessUsersList = permissions?.MainSearch?.usersList?.canAccess;

    // Fetch users only if canAccess is true
    if (canAccessUsersList) {
      const fetchUsers = async () => {
        try {
          const response = await getUsers();
          const sortedUsers = response.data.sort((a: any, b: any) => {

            return a.email.localeCompare(b.email);
          });
          setUsers(sortedUsers); // Assume response.data contains an array of users
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };
      fetchUsers();
    }
  }, []);

  const handleUserChange = (event: SelectChangeEvent<string>) => {
    const selectedUserId = event.target.value as string;
    setSelectedUser(event.target.value as string);
    // Additional logic for selected user can be added here
    if (selectedUserId && courseId) {
      setDifferentUserId(selectedUserId);
      dispatch(getSearchByCourseId({ userId: selectedUserId, courseId: courseId, searchId: "first0001" }));
    }
  };

  const handleStarClick = async (answerDataId: string) => {
    try {
      // Check if the question is currently starred
      const isCurrentlyStarred = starredQuestions.includes(answerDataId);

      // Toggle star locally
      setStarredQuestions(prev =>
        isCurrentlyStarred ? prev.filter(id => id !== answerDataId) : [...prev, answerDataId]
      );

      // Send request to server to update star status
      if (isCurrentlyStarred) {
        await removeFromGlobalFAQ(answerDataId, data.userId, data.courseId, data.searchId);
      } else {
        await addToGlobalFAQ(answerDataId, data.userId, data.courseId, data.searchId);
      }
    } catch (error) {
      console.error("Error toggling star status:", error);
    }
    dispatch(getAllGlobalFAQData({ userId: null, courseId: courseId, searchId: "first0001", answerDataId: null }));
  };
  //const sortedAnswerData = data && data.answerData ? [...data.answerData].sort((a: any, b: any) => new Date(b.answer.date).getTime() - new Date(a.answer.date).getTime()) : [];
  const sortedAnswerData = data && data.answerData
    ? [...data.answerData].sort((a: any, b: any) => {
      const dateA = a.answer?.date ? new Date(a.answer.date).getTime() : 0;
      const dateB = b.answer?.date ? new Date(b.answer.date).getTime() : 0;
      return dateB - dateA;
    })
    : [];

  const filteredData = searchTerm
    ? sortedAnswerData.filter((item: any) =>
      item.answer?.question?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : sortedAnswerData;
  const hasSentToDBOnExpand = useRef<string | false>(false);

  // useEffect for when the panel is expanded
  useEffect(() => {
    if (expanded && hasSentToDBOnExpand.current !== expanded) {
      const panelIndex = parseInt(expanded.replace('panel', ''));
      const panelData = filteredData[panelIndex];

      if (panelData && panelData?.movies && panelData.movies[1]?.semantic && panelData.movies[1]?.semantic[0] && panelData?.answer) {
        const { question, questionId } = panelData?.answer;
        const { courseId, searchId } = panelData?.movies[1]?.semantic[0];
        createMonitoringOpenQuestion(courseId, searchId, questionId, question);
        hasSentToDBOnExpand.current = expanded; // Mark the panel that has been processed
      }
    }
  }, [expanded, filteredData]);

  useEffect(() => {
    if (filteredData.length > 0) {
      const firstNonEmptySemanticPanel = filteredData.findIndex((item: any) =>
        item &&
        item.movies &&
        Array.isArray(item.movies) &&
        item.movies.some((movie: any) => movie?.semantic && movie?.semantic.length > 0)
      );
      const firstAnswerIfEmpty = filteredData.findIndex((item: any) => item?.answer && item.answer.answer);
      if (firstNonEmptySemanticPanel !== -1) {
        setLoadingStates(prev => ({ 'panel1': false }));
        setExpanded(`panel${firstNonEmptySemanticPanel}`);
      }
      else if (firstAnswerIfEmpty !== -1) {
        setLoadingStates(prev => ({ 'panel1': false }));
        setExpanded(`panel${firstAnswerIfEmpty}`);
      }
      else {
        setExpanded(false);
      }
    }
  }, [data?.answerData]);

  useEffect(() => {
    if (data?.answerData) {
      const initialLikeStatus = data.answerData.reduce((acc: any, item: any) => {
        acc[item.answerDataId] = item.likeStatus || null; // Use `item.likeStatus` or default to null
        return acc;
      }, {});
      setLikeStatus(initialLikeStatus);
    }
  }, [data?.answerData]);

  const handleLike = (answerDataId: string, item: any) => {
    addLikeToQuestion(courseId, data.searchId, answerDataId, 'liked');
    setLikeStatus((prev) => ({ ...prev, [answerDataId]: 'liked' }));
    createMonitoringLikedDislikedAllQuestion(courseId, data.searchId, answerDataId, item.answer.question, 'liked');
  };

  const handleDislike = (answerDataId: string, item: any) => {
    addLikeToQuestion(courseId, data.searchId, answerDataId, 'disliked');
    setLikeStatus((prev) => ({ ...prev, [answerDataId]: 'disliked' }));
    createMonitoringLikedDislikedAllQuestion(courseId, data.searchId, answerDataId, item.answer.question, 'disliked');
  };
  const isHebrewOrArabic = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF\u0600-\u06FF]/.test(text);

  const openingAskQuestion = async (searchId: string, answerDataId: string) => {
    await resultOfOpeningAskQuestion(searchId, answerDataId, differentUserId);
  };
  const borderColor = theme.palette.mode === 'dark' ? 'white' : 'black';


  const renderGridItem = (item: any, index: number) => {
    if (!item || !item.answer) return null;
    const isStarred = starredQuestions.includes(item.answerDataId);
    return (
      <Box
        key={index}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px',
          padding: '16px',
          margin: '8px',
          textAlign: 'left',
          width: 'calc(33.33% - 16px)',
          cursor: 'pointer',
          backgroundColor: fullScreenItem === item.answerDataId ? 'rgba(0, 123, 255, 0.1)' : 'inherit',
          '&:hover': {
            backgroundColor: 'rgba(0, 123, 255, 0.1)',
          },
        }}
        onClick={() => handleChangeInFullScreen(`panel${index}`, item)}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Q: {item.answer.question}
          </Typography>
          {/* Star Icon */}
          {permissions?.MainSearch?.star?.canAccess && (
            <Tooltip title={isStarred ? "Unstar this question" : "Star this question"}>
              <IconButton onClick={() => handleStarClick(item.answerDataId)}>
                <StarIcon sx={{ color: isStarred ? 'gold' : 'gray' }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary', mt: 2 }}>
          {new Date(item.answer.date).toLocaleDateString()} {new Date(item.answer.date).toLocaleTimeString()}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ margin: 'auto', mt: 4, width: '95%', padding: '10px', marginBlock: '3rem', marginLeft: '-0.5rem', paddingBottom: '100px', marginTop: '-10px' }}>
      <Box sx={{}}>
        {/* Search input */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 4 }}>
          <TextField
            label="Filter your questions"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            sx={{ width: 400, mr: 2 }}
            placeholder="What question are you looking for?"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && (
                    <IconButton onClick={() => setSearchTerm('')}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          {/* User Dropdown */}
          {permissions?.MainSearch?.usersList?.canAccess && (
            <Select
              value={selectedUser}
              onChange={handleUserChange}
              displayEmpty
              sx={{ width: 200, mr: 2 }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Set the maximum height for the dropdown menu
                    overflowY: 'auto', // Enable vertical scrolling
                  },
                },
              }}
            >
              <MenuItem value="" disabled>Select User</MenuItem>
              {users.map((user) => (
                <MenuItem key={user.userId} value={user.userId}>
                  {user.email} {/* Display email here */}
                </MenuItem>
              ))}
            </Select>
          )}

          {/* Go Full Screen Button */}

          <Button
            variant="contained"
            size="small"
            onClick={goToFaqFullScreen}
            disabled={!data?.answerData || data.answerData.length === 0 || !data.answerData[0]}
            sx={{
              marginRight: '8px',
              padding: '4px 8px',
              backgroundColor: theme.palette.common.white,
              color: theme.palette.common.black,
              border: `1px solid ${theme.palette.common.black}`,  // Adding a black border
              textTransform: 'none',
              fontSize: '0.875rem', // Adjusted font size for consistency with smaller size
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                backgroundColor: theme.palette.grey[200], // Light gray hover effect
                color: theme.palette.common.black,
              },
              '&:disabled': {
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.grey[600],
                border: `1px solid ${theme.palette.grey[400]}`,  // Gray border when disabled
              },
              '&:active': {
                transform: 'scale(0.98)',
              }
            }}
          >
            {currentView === 'search' ? 'Back to Video' : 'Go Full Screen'}
          </Button>
          {permissions?.MainSearch?.analyzeButton?.canAccess && permissions?.MainSearch?.analyzeButton?.parameter1 === domain && (
            <Button
              variant="contained"
              size="small"
              onClick={goAnalyzeContent}
              sx={{
                marginLeft: '8px',
                padding: '4px 8px',
                backgroundColor: theme.palette.common.white,
                color: theme.palette.common.black,
                border: `1px solid ${theme.palette.common.black}`,  // Adding a black border
                textTransform: 'none',
                fontSize: '0.875rem', // Adjusted font size for consistency with smaller size
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: theme.palette.grey[200], // Light gray hover effect
                  color: theme.palette.common.black,
                },
                '&:disabled': {
                  backgroundColor: theme.palette.grey[300],
                  color: theme.palette.grey[600],
                  border: `1px solid ${theme.palette.grey[400]}`,  // Gray border when disabled
                },
                '&:active': {
                  transform: 'scale(0.98)',
                }
              }}
            >
              {currentView === 'search' ? 'Back to Video' : 'Analyze Screen'}
            </Button>)}

          {maxQuestions && remainingQuestions && <Tooltip title={`You can ask ${maxQuestions && remainingQuestions !== null ? maxQuestions - remainingQuestions : 0} more questions out of ${maxQuestions || 0}.`}>
            <Box
              sx={{
                backgroundColor: '#007BFF', // Blue background
                padding: '10px 20px',
                borderRadius: '8px', // Rounded corners
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for professional look
                color: 'white', // Text color
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '1rem', // Responsive font size
              }}
            >
              <Typography variant="h6" sx={{ color: 'white' }}>
                {remainingQuestions}/{maxQuestions}
              </Typography>
            </Box>
          </Tooltip>}
        </Box>
        {currentView === 'search' ? (
          <Grid container sx={{ justifyContent: 'center' }}>
            {data && data.answerData && data.answerData.map((item: any, index: any) => renderGridItem(item, index))}
          </Grid>
        ) : (
          data && data.answerData && filteredData.map((item: any, index: any) => {
            const isStarred = starredQuestions.includes(item.answerDataId);
            if (!item || !item.answer) {
              // Handle the null or undefined item appropriately
              return null; // Skip rendering this item
            }
            return (
              <Accordion
                ref={(el) => (accordionRefs.current[`panel${index}`] = el)}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`, item)}
                key={index} sx={{ background: colors[index % colors.length], color: theme.palette.text.primary, marginTop: '0.1rem', borderRadius: '5px', border: '1px solid', borderColor: borderColor }}>
                <AccordionSummary
                  sx={{
                    direction: isHebrewOrArabic(item?.answer?.question) ? 'rtl' : 'ltr',
                    padding: '0 20px',
                    display: 'flex',
                    alignItems: 'center', // Align items vertically in the center
                    justifyContent: 'space-between' // Align content to the ends
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', mb: '-8px' }}>
                    {/* Question text with ellipsis */}
                    <Tooltip title={item?.answer?.question} placement="top">
                      <Typography
                        sx={{
                          direction: isHebrewOrArabic(item?.answer?.question) ? 'rtl' : 'ltr',
                          whiteSpace: 'normal', // Always unwrap text when accordion is expanded
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '100%', // Ensure the text stays within the container
                          mb: '-8px' // Move the text up by adjusting the margin-bottom
                        }}
                      >
                        {expanded === `panel${index}`
                          ? item?.answer?.question // Full text when expanded
                          : item?.answer?.question?.length > 40 // Truncate if text length exceeds 55 characters
                            ? `${item.answer.question.slice(0, 40)}...` // Show truncated text with ellipsis
                            : item?.answer?.question || ''} {/* Fallback to empty string if undefined */}
                      </Typography>
                    </Tooltip>
                  </Box>
                  {/* Container for the date and delete button */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {expanded === `panel${index}` && (
                      <React.Fragment>

                      </React.Fragment>
                    )}
                    {expanded !== `panel${index}` && (
                      <React.Fragment>
                        <Tooltip title="Delete this section" placement="top">
                          <IconButton onClick={() => setDeleteConfirmOpen(item)}>
                            <DeleteIcon sx={{ cursor: 'pointer', fontSize: '20px', color: theme.palette.mode === 'dark' ? '#e0ebeb' : 'gray' }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={new Date(item?.answer?.date).toLocaleTimeString('en-GB')} placement="top">
                          <Typography
                            variant="caption"
                            sx={{
                              direction: isHebrewOrArabic(item?.answer?.question) ? 'ltr' : 'rtl',
                              color: 'text.secondary',
                              mr: 1, // Margin to create space between the date and the delete icon
                            }}
                          >
                            {new Date(item?.answer?.date).toLocaleDateString()}
                          </Typography>

                        </Tooltip>
                        {permissions?.MainSearch?.star?.canAccess && (
                          <Tooltip title={isStarred ? "Unstar this question" : "Star this question"}>
                            <IconButton onClick={(e) => { e.stopPropagation(); handleStarClick(item.answerDataId); }}>
                              <StarIcon sx={{ color: isStarred ? 'gold' : 'gray' }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {/* you can add like dislike to here */}




                      </React.Fragment>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {loadingStates[`panel${index}`] ? (
                    <Box sx={{ marginLeft: '160px' }}>
                      <LoadingDots message={"You answer for this question is coming"} />
                    </Box>
                  ) : (
                    <React.Fragment>
                      <Dialog
                        open={deleteConfirmOpen ? true : false}
                        onClose={() => setDeleteConfirmOpen(null)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        key={index}
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Confirm Deletion"}
                        </DialogTitle>
                        <DialogContent key={index}>
                          <DialogContentText id="alert-dialog-description" key={index}>
                            Are you sure you want to Remove this section?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions key={index}>
                          <Button onClick={() => setDeleteConfirmOpen(null)}>Cancel</Button>
                          <Button key={index} onClick={handleMovieDelete} autoFocus>
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>

                      {/* Add numbering to Semantics and Indexes */}
                      <Tooltip title="Delete this section" placement="top">
                        <IconButton onClick={() => setDeleteConfirmOpen(item)}>
                          <DeleteIcon sx={{ cursor: 'pointer', fontSize: '30px', color: theme.palette.mode === 'dark' ? '#e0ebeb' : 'gray' }} />
                        </IconButton>
                      </Tooltip>
                      {permissions?.MainSearch?.star?.canAccess && (
                        <Tooltip title={isStarred ? "Unstar this question" : "Star this question"}>
                          <IconButton onClick={() => handleStarClick(item.answerDataId)}>
                            <StarIcon sx={{ color: isStarred ? 'gold' : 'gray' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Typography
                        variant="caption"
                        sx={{
                          direction: isHebrewOrArabic(item?.answer?.question) ? 'ltr' : 'rtl',
                          color: 'text.secondary',
                          ml: 2, // Space between delete icon and date
                          fontSize: '16px'
                        }}
                      >
                        {new Date(item?.answer?.date).toLocaleDateString()} {new Date(item?.answer?.date).toLocaleTimeString('en-GB')}
                      </Typography>
                      {item.movies && item.movies.map((movie: any, mIndex: number) => (
                        <React.Fragment key={mIndex}>
                          {movie.semantic && movie.semantic.map((semantic: Semantic, sIndex: number) => (
                            <React.Fragment key={sIndex}>
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', direction: isHebrewOrArabic(item.answer.question) ? 'rtl' : 'ltr' }}>
                                  {isHebrewOrArabic(item.answer.question) ? `תימוכין ${sIndex + 1}:` : `Reference ${sIndex + 1}:`}
                                </Typography>
                                <VideoSemantic
                                  dataAnswerId={item.answerDataId}
                                  key={sIndex}
                                  data={semantic}
                                  isOpen={false} // Since we are using accordion, isOpen is controlled differently                           
                                />
                              </Box>
                            </React.Fragment>
                          ))}
                          {movie.index && movie.index.map((indexItem: Index, iIndex: number) => (
                            <React.Fragment key={iIndex}>
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', direction: isHebrewOrArabic(item.answer.question) ? 'rtl' : 'ltr' }}>
                                  {isHebrewOrArabic(item.answer.question) ? `תשובה ${iIndex + 1}:` : `Answer ${iIndex + 1}:`}
                                </Typography>
                                <VideoIndex
                                  key={iIndex}
                                  question={item.answer.question}
                                  data={indexItem}
                                  isOpen={false} // Since we are using accordion, isOpen is controlled differently                         
                                />
                              </Box>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                      <Typography variant="h6" sx={{ fontWeight: 'bold', direction: isHebrewOrArabic(item.answer.question) ? 'rtl' : 'ltr' }}>
                        {isHebrewOrArabic(item.answer.question) ? `תשובה מקיפה:` : `Comprehensive answer:`}
                      </Typography>
                      {item.answer && item.answer.answer && item.answer.answer === "Waiting for an answer..." ?
                        <LoadingDots
                          isEnabled={true}
                          messages={[
                            "Let's first understand what you're asking",
                            "We're carefully processing the details",
                            "Now, we're diving deep to analyze your question",
                            "Checking the best possible sources for your answer",
                            "Ensuring accuracy as we gather data",
                            "Almost there, compiling everything for you",
                            "Hang tight, we're fetching exactly what you need",
                            "Your answer is just moments away"
                          ]}
                        />
                        :
                        <VideoAnswer item={item.answer} data={data} answerDataId={item.answerDataId} />}
                    </React.Fragment>
                  )}
                </AccordionDetails>
              </Accordion>
            )
          }
          ))}

      </Box>
    </Box >
  )
}

export default MainSearch;
