import React, { useState, useEffect, useRef } from 'react';
import { reportOnQuestion, createMonitoringUserAnswerQuiz, createMonitoringUserOpenAnswerQuiz } from '../../../../api/serverApis/monitoringApi';
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Box, Radio, RadioGroup, FormControlLabel, FormControl, Button, Divider, Typography, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import MainQuestionHtmlShow from '../MainQuestionHtmlShow';
import VideoBoxInteract from '../../VideoBoxInteract';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CancelIcon from '@mui/icons-material/Cancel';
import { useVideoProgress } from '../../videoPlayer/VideoProgressContext';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { getQuizByCourseIdSlice, getRandomQuizByCourseIdSlice } from '../../../../features/quizSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store';
import { setSelectedVideoDetails } from '../../../../features/coursesSlice';
import { use } from 'passport';

interface GeneralQuestionComponentProps {
    courseId: string;
    theme: any;
    videoData: any;
    onBackToVideo: () => void;
    onNextQuizSet: () => void;
    onPreviousQuizSet: () => void;
    disableNext: boolean;
    disablePrevious: boolean;
    handleGetQuizData: (movieId: string, topicNumber: string) => void;
    getQuizFromPlayer: boolean;
}

const GeneralQuestionComponent: React.FC<GeneralQuestionComponentProps> = ({ courseId, theme, videoData, onBackToVideo, onNextQuizSet, onPreviousQuizSet, disableNext, disablePrevious, handleGetQuizData, getQuizFromPlayer }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);
    const [showAnswer, setShowAnswer] = useState<boolean>(false);
    const [showMovie, setShowMovie] = useState<boolean>(false);
    const [radioGroupHeight, setRadioGroupHeight] = useState<number>(0);
    const [openVideoInteract, setOpenVideoInteract] = useState(false);
    const lightColors = ['#F0F0F0', '#E0E0E0'];
    const darkColors = ['#333333', '#404040'];
    const colors = theme.palette.mode === 'dark' ? darkColors : lightColors;
    //const { setCurrentlyPlayingSubVideo } = useVideoProgress();
    const radioGroupRef = useRef<HTMLDivElement>(null);
    const [reportColor, setReportColor] = useState<string>('default');
    const [solvedColor, setSolvedColor] = useState<string>('default');
    const [notSolvedColor, setNotSolvedColor] = useState<string>('default');
    const [notSolved, setNotSolved] = useState<boolean>(false);
    const [solved, setSolved] = useState<boolean>(false);
    const [nextSetOfQuestion, setNextSetOfQuestion] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null); // NEW CODE: Track selected option
    const [showFeedback, setShowFeedback] = useState<boolean>(false);
    const [sortedAnswerData, setSortedAnswerData] = useState<any[]>([]);
    const allQuizzesData = useSelector((state: RootState) => state.quiz.allQuizzesData);
    const [correctAnswers, setCorrectAnswers] = useState<number>(0);
    const [totalAttempts, setTotalAttempts] = useState<number>(0);

    const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
    const [highlightCorrectAnswer, setHighlightCorrectAnswer] = useState<boolean>(false);
    const [answeredQuestions, setAnsweredQuestions] = useState<Set<string>>(new Set());

    const [questionTime, setQuestionTime] = useState<number>(180); // Default 30 seconds for each question
    const [totalTime, setTotalTime] = useState<number>(7200); // Default 5 minutes for all questions
    const [remainingQuestionTime, setRemainingQuestionTime] = useState<number>(questionTime);
    const [remainingTotalTime, setRemainingTotalTime] = useState<number>(totalTime);
    const [isEditingQuestionTime, setIsEditingQuestionTime] = useState(false);
    const [isEditingTotalTime, setIsEditingTotalTime] = useState(false);
    const [showScoreSection, setShowScoreSection] = useState(true);
    const [showTimeSection, setShowTimeSection] = useState(true);
    const [openQuestionTimePopup, setOpenQuestionTimePopup] = useState(false);
    const [openTestTimePopup, setOpenTestTimePopup] = useState(false);
    const [isQuestionTimerPaused, setIsQuestionTimerPaused] = useState<boolean>(false);
    const [isTestTimerPaused, setIsTestTimerPaused] = useState<boolean>(false);
    const [videoDataState, setVideoDataState] = useState<any>(videoData);
    const [anchorElQuestionTime, setAnchorElQuestionTime] = useState<null | HTMLElement>(null);
    const [anchorElTotalTime, setAnchorElTotalTime] = useState<null | HTMLElement>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setVideoDataState(videoData);
    }, [videoData]);
    useEffect(() => {
        setLoading(true);
        dispatch(getRandomQuizByCourseIdSlice({ courseId }))
            .then(() => setLoading(false))
            .catch((error) => {
                console.error('Error fetching quiz data:', error);
                setLoading(false);
            });
    }, [courseId, dispatch]);

    useEffect(() => {
        if (allQuizzesData && allQuizzesData.length > 0) {
            setSortedAnswerData(allQuizzesData);
            setLoading(false);
        } else {
            console.warn('No quizzes found for the given course.');
            setLoading(false);
        }
    }, [allQuizzesData]);

    useEffect(() => {
        if (sortedAnswerData.length > 0) {
            setSelectedQuestionIndex(0);
        }
    }, [sortedAnswerData.length]);

    useEffect(() => {
        if (sortedAnswerData.length > 0) {
            const currentQuestion = sortedAnswerData[selectedQuestionIndex];

            // Check if there are any votes that should trigger an icon color change
            if (currentQuestion.problemVote > 0 || currentQuestion.typoVote > 0 || currentQuestion.questionNotGoodVote > 0 || currentQuestion.answerNotGoodVote > 0) {
                setReportColor('red');
            }
            else {
                setReportColor('default');
            }
            if (currentQuestion.didntSolveVote > 0) {
                setNotSolved(true);
                setNotSolvedColor('orange');
            }
            else {
                setNotSolved(false);
                setNotSolvedColor('default');
            }
            if (currentQuestion.easyQuestionVote > 0 || currentQuestion.mediumQuestionVote > 0 || currentQuestion.hardQuestionVote > 0 || currentQuestion.solvedVote > 0) {
                setSolved(true);
                setSolvedColor('green');
            }
            else {
                setSolved(false);
                setSolvedColor('default');
            }
        }
    }, [sortedAnswerData, selectedQuestionIndex]);
    const loadingReturn = () => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
            <Typography variant="h6" sx={{ ml: 2 }}>
                Loading quizzes...
            </Typography>
        </Box>
    );
    // useEffect(() => {
    //   setCurrentlyPlayingSubVideo({ movieId: videoData.articleId, subVideoId: videoData.id });
    // }, [videoData]);

    // useEffect(() => {
    //   if (radioGroupRef.current) {
    //     setRadioGroupHeight(radioGroupRef.current.clientHeight);
    //   }
    // }, [radioGroupRef.current]);



    const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedQuestionIndex(parseInt(event.target.value, 10));
        setShowAnswer(false); // Close the answer when a new question is selected
        setShowFeedback(false); // Reset feedback when switching questions
        setSelectedOption(null); // Reset selected option
        setHighlightCorrectAnswer(false);
        setRemainingQuestionTime(questionTime);
        dispatch(setSelectedVideoDetails({ movieId: sortedAnswerData[parseInt(event.target.value, 10)].articleId, id: sortedAnswerData[parseInt(event.target.value, 10)].topicNumber, courseId: courseId ? courseId : "" }));
    };
    const handleOptionSelect = (option: string, selectedQuestion: any) => {
        setSelectedOption(option);
        setShowFeedback(true); // Show feedback after an option is selected
        if (!answeredQuestions.has(selectedQuestion.questionId)) {
            if (option === correctAnswer) {
                setCorrectAnswers((prev) => prev + 1);
                setShowAnswer(true);
            }
            else {
                setIsCorrect(false);
                setHighlightCorrectAnswer(true); // Trigger animation for highlighting
                setTimeout(() => setShowAnswer(true), 1000); // Delay to show explanation after animation
            }
            setTotalAttempts((prev) => prev + 1);
            setAnsweredQuestions((prev) => new Set(prev).add(selectedQuestion.questionId));
        }
        createMonitoringUserAnswerQuiz(selectedQuestion.questionId, selectedQuestion.articleId, selectedQuestion.topicNumber, selectedQuestion.topic, option, selectedQuestion.multiChoiceAnswerJson);
    };
    const calculateGrade = () => {
        return answeredQuestions.size > 0
            ? Math.round((correctAnswers / answeredQuestions.size) * 100)
            : 0;
    };
    const calculateRemainingQuestions = (questions: any[], answeredQuestions: Set<string>) => {
        try {
            if (!questions || questions.length === 0) return 0;

            const remaining = questions.filter((question) => {
                if (!question || !question.multiChoiceJson) return false;

                let parsedOptions;

                try {
                    parsedOptions = typeof question.multiChoiceJson === 'string'
                        ? JSON.parse(question.multiChoiceJson.replace(/[\u201C\u201D]/g, '"')) // Replacing smart quotes
                        : question.multiChoiceJson;
                } catch (parseError) {
                    console.error(`Error parsing multiChoiceJson for question ID ${question.questionId}:`, parseError);
                    return false;  // Skip the question if parsing fails
                }

                return parsedOptions && Object.keys(parsedOptions).length > 0 && !answeredQuestions.has(question.questionId);
            });

            console.log("Remaining questions calculated:", remaining.length);
            return remaining.length;
        } catch (error) {
            console.error('Error calculating remaining questions:', error);
            return 0;
        }
    };
    const remainingQuestions = calculateRemainingQuestions(sortedAnswerData, answeredQuestions);
    const handleNextQuestion = () => {
        setSelectedQuestionIndex((prevIndex) => (prevIndex + 1) % sortedAnswerData.length);
        setShowAnswer(false); // Close the answer when navigating to the next question
        setShowAnswer(false); // Hide answer
        setShowFeedback(false); // Reset feedback
        setSelectedOption(null); // Reset selected option (clear radio button selection)
        setHighlightCorrectAnswer(false);
        setRemainingQuestionTime(questionTime);
        if (selectedQuestionIndex < sortedAnswerData.length - 1) {
            dispatch(setSelectedVideoDetails({ movieId: sortedAnswerData[selectedQuestionIndex + 1].articleId, id: sortedAnswerData[selectedQuestionIndex + 1].topicNumber, courseId: courseId ? courseId : "" }));
        }
    };


    const handlePreviousQuestion = () => {
        setSelectedQuestionIndex((prevIndex) => (prevIndex - 1 + sortedAnswerData.length) % sortedAnswerData.length);
        setShowAnswer(false); // Close the answer when navigating to the previous question
        setShowAnswer(false); // Hide answer
        setShowFeedback(false); // Reset feedback
        setSelectedOption(null); // Reset selected option (clear radio button selection)
        setHighlightCorrectAnswer(false);
        setRemainingQuestionTime(questionTime);
        if (selectedQuestionIndex > 0) {
            dispatch(setSelectedVideoDetails({ movieId: sortedAnswerData[selectedQuestionIndex - 1].articleId, id: sortedAnswerData[selectedQuestionIndex - 1].topicNumber, courseId: courseId ? courseId : "" }));
        }
    };
    const handleToggleMovie = () => {
        setShowMovie(!showMovie);
        setOpenVideoInteract(true);
    };
    const handleShowAnswer = (selectedQuestion: any) => {
        setShowAnswer(!showAnswer);
        createMonitoringUserOpenAnswerQuiz(selectedQuestion.questionId, selectedQuestion.articleId, selectedQuestion.topicNumber, selectedQuestion.topic);
    };

    const handleNextSetOfQuestions = () => {
        setNextSetOfQuestion(!nextSetOfQuestion);
        onNextQuizSet();
    }
    const handlePerviousSetOfQuestions = () => {
        setNextSetOfQuestion(!nextSetOfQuestion);
        onPreviousQuizSet();
    }
    const isHebrewOrArabic = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF\u0600-\u06FF]/.test(text);

    const selectedQuestion = sortedAnswerData.length > 0 ? sortedAnswerData[selectedQuestionIndex] : null;
    const multiChoiceOptions = (() => {
        if (!selectedQuestion || !selectedQuestion.multiChoiceJson) {
            console.warn('Selected question or multiChoiceJson is undefined');
            return null;
        }
        try {
            return typeof selectedQuestion.multiChoiceJson === 'string'
                ? JSON.parse(selectedQuestion.multiChoiceJson)
                : selectedQuestion.multiChoiceJson;
        } catch (error) {
            console.error('Error parsing multiChoiceJson:', error);
            return null; // Return null if parsing fails
        }
    })();

    const correctAnswer = (() => {
        try {
            const rawJson = selectedQuestion?.multiChoiceAnswerJson;
            if (rawJson) {
                // Replace smart quotes with standard quotes
                const sanitizedJson = rawJson.replace(/[\u201C\u201D]/g, '"');
                return JSON.parse(sanitizedJson).correctChoice;
            }
            return null;
        } catch (error) {
            console.error('Error parsing multiChoiceAnswerJson:', error);
            return null; // Return null if parsing fails
        }
    })();

    const languageLabel = isHebrewOrArabic(selectedQuestion?.topic || '') ? 'תרגיל' : 'Exercise';
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleReportClick = (event: React.MouseEvent<HTMLElement>) => {
        setReportColor('red');
        setAnchorEl(event.currentTarget);
    };

    const handleReportClose = () => {
        setAnchorEl(null);
    };

    const handleReportSelect = async (reportType: string) => {
        try {
            if (reportType === 'there is no problem') {
                setReportColor('default');
            }
            handleReportClose();
            await reportOnQuestion(videoData.articleId, videoData.id, selectedQuestion.questionId, reportType);
            handleGetQuizData(videoData.articleId, videoData.id);
        }
        catch (error) {
            console.error('MainQuestionComponent: [handleReportSelect]: ' + error);
        }
    };

    const [solvedAnchorEl, setSolvedAnchorEl] = useState<null | HTMLElement>(null);

    const handleSolvedClick = (event: React.MouseEvent<HTMLElement>) => {
        try {
            setSolved(!solved);
            setSolvedColor(solved ? 'default' : 'green');
            setSolvedAnchorEl(event.currentTarget);
            const difficulty = solved ? '' : 'solved';
            //reportOnQuestion(videoData.articleId, videoData.id, selectedQuestion.questionId, difficulty);
        }
        catch (error) {
            console.error('MainQuestionComponent: [handleSolvedClick]: ' + error);
        }
    };

    const handleSolvedClose = () => {
        setSolvedAnchorEl(null);
    };

    const handleSolvedSelect = async (difficulty: string) => {
        try {
            console.log('Solved difficulty:', difficulty);
            handleSolvedClose();
            await reportOnQuestion(videoData.articleId, videoData.id, selectedQuestion.questionId, difficulty);
            handleGetQuizData(videoData.articleId, videoData.id);
        }
        catch (error) {
            console.error('MainQuestionComponent: [handleSolvedSelect]: ' + error);
        };
    };
    const handleNotSolvedClick = async () => {
        try {
            setNotSolved(!notSolved);
            setNotSolvedColor(notSolved ? 'default' : 'orange');
            await reportOnQuestion(videoData.articleId, videoData.id, selectedQuestion.questionId, notSolved ? '' : 'didntSolve');
            handleGetQuizData(videoData.articleId, videoData.id);
        }
        catch (error) {
            console.error('MainQuestionComponent: [handleNotSolvedClick]: ' + error);
        }
    };
    const renderMultipleChoiceOptions = (
        options: Record<string, string>,
        correctOption: string,
        selectedOption: string | null,
        feedback: boolean,
        selectedQuestion: any
    ) => {
        const directionMain = isHebrewOrArabic(multiChoiceOptions.optionA) ? 'rtl' : 'ltr';

        return (
            <Box
                sx={{
                    marginTop: '20px',
                    textAlign: 'center',
                    direction: directionMain,
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '15px', // Add spacing between rows and columns
                }}
            >
                <FormControl component="fieldset">
                    <RadioGroup
                        name="multiple-choice"
                        value={selectedOption}
                        onChange={(e) => handleOptionSelect(e.target.value, selectedQuestion)}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row', // Align in a row for two items per row
                            flexWrap: 'wrap', // Allow wrapping to the next line
                            justifyContent: 'center',
                            gap: '20px',
                        }}
                    >
                        {Object.entries(options).map(([key, value]: [string, string], index) => {
                            const direction = isHebrewOrArabic(value) ? 'rtl' : 'ltr';
                            const optionLabel = String.fromCharCode(65 + index); // Convert 0,1,2,3 to A,B,C,D
                            const isSelected = selectedOption === key;
                            const isCorrect = key === correctOption;
                            const isIncorrect = isSelected && !isCorrect;

                            return (
                                <Box
                                    key={key}
                                    sx={{
                                        width: '45%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '15px',
                                        border: `2px solid ${isSelected
                                            ? isCorrect
                                                ? theme.palette.success.main // Green border for correct
                                                : theme.palette.error.main   // Red border for incorrect
                                            : theme.palette.divider          // Neutral border color
                                            }`,
                                        borderRadius: '12px',
                                        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                                        backgroundColor: theme.palette.background.paper,
                                        transition: 'all 0.3s ease-in-out',
                                        position: 'relative',
                                        animation: highlightCorrectAnswer && key === correctOption
                                            ? 'pulse 1s ease-in-out infinite alternate'
                                            : 'none',
                                        '&:hover': {
                                            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)', // Neutral effect on hover
                                            transform: 'scale(1.02)',  // Slight zoom effect for UX
                                        },
                                        '@keyframes pulse': {
                                            '0%': { borderColor: theme.palette.success.light || '#a5d6a7', transform: 'scale(1)' },
                                            '100%': { borderColor: theme.palette.success.dark || '#1b5e20', transform: 'scale(1.05)' },
                                        },
                                    }}
                                >
                                    {/* Letter before the radio button */}
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            marginRight: '10px',
                                            fontSize: '1.2rem',
                                            direction,
                                            color: theme.palette.text.primary,
                                        }}
                                    >
                                        {optionLabel}
                                    </Typography>

                                    {/* Radio button and label */}
                                    <FormControlLabel
                                        value={key}
                                        control={<Radio size="medium" />}
                                        label={
                                            <Typography
                                                sx={{
                                                    direction,
                                                    textAlign: direction === 'rtl' ? 'right' : 'left',
                                                    fontSize: '1rem',
                                                    fontWeight: '500',
                                                    color: theme.palette.text.secondary,
                                                }}
                                            >
                                                {value}
                                            </Typography>
                                        }
                                        sx={{ flex: 1 }}
                                    />

                                    {/* Feedback icon (✔️ or ❌) in the top-right corner */}
                                    {feedback && isSelected && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                right: '5px',
                                                top: '5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {isCorrect ? (
                                                <CheckCircleIcon
                                                    sx={{ color: theme.palette.success.main, fontSize: '24px' }}
                                                />
                                            ) : (
                                                <HighlightOffIcon sx={{ color: theme.palette.error.main, fontSize: '24px' }} />
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            </Box>
        );
    };
    const handlePauseResumeQuestionTimer = () => {
        setIsQuestionTimerPaused((prev) => !prev);
    };

    const handlePauseResumeTestTimer = () => {
        setIsTestTimerPaused((prev) => !prev);
    };
    useEffect(() => {
        let questionTimer: NodeJS.Timeout;
        let testTimer: NodeJS.Timeout;

        if (!isQuestionTimerPaused) {
            questionTimer = setInterval(() => {
                setRemainingQuestionTime((prev) => {
                    if (prev <= 1) {
                        clearInterval(questionTimer);
                        setOpenQuestionTimePopup(true);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        if (!isTestTimerPaused) {
            testTimer = setInterval(() => {
                setRemainingTotalTime((prev) => {
                    if (prev <= 1) {
                        clearInterval(testTimer);
                        setOpenTestTimePopup(true);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => {
            clearInterval(questionTimer);
            clearInterval(testTimer);
        };
    }, [selectedQuestionIndex, totalTime, isQuestionTimerPaused, isTestTimerPaused]);

    const handleQuestionTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTime = parseInt(e.target.value, 10);
        setQuestionTime(newTime);
        setRemainingQuestionTime(newTime);
    };

    const handleTotalTimeChange = (e: React.ChangeEvent<HTMLInputElement>, isMinutes: boolean = false) => {
        const newTotalTime = parseInt(e.target.value, 10);

        if (isMinutes) {
            setTotalTime(newTotalTime * 60);  // Convert minutes to seconds
            setRemainingTotalTime(newTotalTime * 60);
        } else {
            setTotalTime(newTotalTime);
            setRemainingTotalTime(newTotalTime);
        }
    };
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>, type: 'question' | 'total') => {
        if (type === 'question') {
            setAnchorElQuestionTime(event.currentTarget);
        } else {
            setAnchorElTotalTime(event.currentTarget);
        }
    };

    const handleCloseMenu = () => {
        setAnchorElQuestionTime(null);
        setAnchorElTotalTime(null);
    };
    const scoreRender = () => {
        const isHebrew = isHebrewOrArabic(selectedQuestion?.topic || '');

        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '20px',
                    borderRadius: '12px',
                    width: '100%',
                    maxWidth: '80%',
                    margin: 'auto',
                    marginTop: '120px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    border: `2px solid ${theme.palette.primary.light}`,
                    background: `linear-gradient(135deg, ${theme.palette.background.paper} 30%, ${theme.palette.primary.light} 100%)`,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}
                >
                    {/* Toggle Show/Hide Button */}
                    <Tooltip title={isHebrew ? (showScoreSection && showTimeSection ? 'הסתר הכל' : 'הצג הכל') : (showScoreSection && showTimeSection ? 'Hide All' : 'Show All')}>
                        <IconButton
                            onClick={() => {
                                setShowScoreSection((prev) => !prev);
                                setShowTimeSection((prev) => !prev);
                            }}
                            sx={{
                                background: theme.palette.primary.main,
                                color: theme.palette.common.white,
                                '&:hover': {
                                    background: theme.palette.primary.dark,
                                },
                            }}
                        >
                            {showScoreSection && showTimeSection ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </Tooltip>

                    {/* Get New Set of Quiz Button */}
                    <Tooltip title={isHebrew ? 'קבל סט חדש של שאלות' : 'Get New Set of Quiz'}>
                        <IconButton
                            onClick={() => {
                                setLoading(true);
                                dispatch(getRandomQuizByCourseIdSlice({ courseId }))
                                    .then(() => setLoading(false))
                                    .catch((error) => {
                                        console.error('Error fetching new quiz data:', error);
                                        setLoading(false);
                                    });
                            }}
                            sx={{
                                background: theme.palette.secondary.main,
                                color: theme.palette.common.white,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                },
                            }}
                        >
                            <RotateLeftIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                {showScoreSection && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            padding: '0',
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            marginTop: '20px',
                        }}
                    >
                        <Box sx={{ textAlign: 'center', flex: 1 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                                {isHebrew ? 'ניקוד' : 'Score'}
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    color: correctAnswers > 0 ? theme.palette.success.main : theme.palette.error.main,
                                }}
                            >
                                {correctAnswers}/{totalAttempts}
                            </Typography>
                        </Box>

                        <Box sx={{ textAlign: 'center', flex: 1 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                                {isHebrew ? 'ציון' : 'Grade'}
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    color: calculateGrade() > 75 ? theme.palette.success.dark : theme.palette.warning.main,
                                }}
                            >
                                {calculateGrade()}%
                            </Typography>
                        </Box>

                        <Box sx={{ textAlign: 'center', flex: 1 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                                {isHebrew ? 'נותר' : 'Remaining'}
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    color: remainingQuestions > 0 ? theme.palette.info.main : theme.palette.error.main,
                                }}
                            >
                                {remainingQuestions}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {showTimeSection && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginTop: '20px',
                        }}
                    >
                        <Box sx={{ textAlign: 'center', flex: 1 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                                {isHebrew ? 'זמן לשאלה (שניות)' : 'Time per Question (seconds)'}
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: theme.palette.info.main }}>
                                {Math.floor(remainingQuestionTime / 60)}:{(remainingQuestionTime % 60).toString().padStart(2, '0')}
                            </Typography>
                            <IconButton onClick={handlePauseResumeQuestionTimer}>
                                {isQuestionTimerPaused ? <PlayArrowIcon color="primary" /> : <PauseIcon color="primary" />}
                            </IconButton>
                            <IconButton onClick={(e) => handleOpenMenu(e, 'question')}>
                                <EditIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorElQuestionTime}
                                open={Boolean(anchorElQuestionTime)}
                                onClose={handleCloseMenu}
                            >
                                <MenuItem>
                                    <input
                                        type="number"
                                        value={questionTime}
                                        onChange={handleQuestionTimeChange}
                                        style={{
                                            width: '60px',
                                            padding: '5px',
                                            textAlign: 'center',
                                            borderRadius: '5px',
                                            border: `1px solid ${theme.palette.divider}`,
                                        }}
                                    />
                                    <IconButton onClick={() => setIsEditingQuestionTime(false)}>
                                        <SaveIcon color="primary" />
                                    </IconButton>
                                </MenuItem>
                            </Menu>
                        </Box>

                        <Box sx={{ textAlign: 'center', flex: 1 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                                {isHebrew ? 'זמן לכל המבחן (דקות)' : 'Total Time (minutes)'}
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: theme.palette.info.main }}>
                                {Math.floor(remainingTotalTime / 60)}:{(remainingTotalTime % 60).toString().padStart(2, '0')}
                            </Typography>
                            <IconButton onClick={handlePauseResumeTestTimer}>
                                {isTestTimerPaused ? <PlayArrowIcon color="primary" /> : <PauseIcon color="primary" />}
                            </IconButton>
                            <IconButton onClick={(e) => handleOpenMenu(e, 'total')}>
                                <EditIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorElTotalTime}
                                open={Boolean(anchorElTotalTime)}
                                onClose={handleCloseMenu}
                            >
                                <MenuItem>
                                    <input
                                        type="number"
                                        value={totalTime / 60}
                                        onChange={(e) => handleTotalTimeChange(e, true)}
                                        style={{
                                            width: '60px',
                                            padding: '5px',
                                            textAlign: 'center',
                                            borderRadius: '5px',
                                            border: `1px solid ${theme.palette.divider}`,
                                        }}
                                    />
                                    <IconButton onClick={() => setIsEditingTotalTime(false)}>
                                        <SaveIcon color="primary" />
                                    </IconButton>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                )}
                {/* Popup for Question Time End */}
                <Dialog
                    open={openQuestionTimePopup}
                    onClose={() => setOpenQuestionTimePopup(false)}
                    PaperProps={{
                        sx: {
                            borderRadius: '16px',
                            padding: '20px',
                            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
                            textAlign: 'center',
                        },
                    }}
                >
                    <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem', color: theme.palette.primary.main }}>
                        {isHebrew ? '⏳ זמן השאלה הסתיים' : '⏳ Time for the Question is Up'}
                    </DialogTitle>
                    <DialogContent>
                        <Typography sx={{ fontSize: '1.2rem', color: theme.palette.text.secondary }}>
                            {isHebrew
                                ? 'הזמן שלך לשאלה זו הסתיים. עבור לשאלה הבאה.'
                                : 'Your time for this question has ended. Please move to the next question.'}
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button
                            onClick={() => setOpenQuestionTimePopup(false)}
                            color="primary"
                            variant="contained"
                            sx={{
                                borderRadius: '30px',
                                padding: '10px 30px',
                                textTransform: 'none',
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            {isHebrew ? 'אישור' : 'OK'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Popup for Total Test Time End */}
                <Dialog
                    open={openTestTimePopup}
                    onClose={() => setOpenTestTimePopup(false)}
                    PaperProps={{
                        sx: {
                            borderRadius: '16px',
                            padding: '20px',
                            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
                            textAlign: 'center',
                        },
                    }}
                >
                    <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem', color: theme.palette.error.main }}>
                        {isHebrew ? '⌛ זמן המבחן הסתיים' : '⌛ Test Time is Up'}
                    </DialogTitle>
                    <DialogContent>
                        <Typography sx={{ fontSize: '1.2rem', color: theme.palette.text.secondary }}>
                            {isHebrew
                                ? 'זמן המבחן נגמר. אנא הגש את המבחן.'
                                : 'The test time is over. Please submit your answers.'}
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button
                            onClick={() => setOpenTestTimePopup(false)}
                            color="error"
                            variant="contained"
                            sx={{
                                borderRadius: '30px',
                                padding: '10px 30px',
                                textTransform: 'none',
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            {isHebrew ? 'אישור' : 'OK'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Box sx={{ width: '100%', mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        onClick={handleNextQuestion}
                        endIcon={<NavigateNextIcon />}
                        sx={{
                            background: theme.palette.primary.main,
                            color: theme.palette.common.white,
                            padding: '10px 20px',
                            borderRadius: '30px',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            '&:hover': {
                                background: theme.palette.primary.dark,
                            },
                        }}
                    >
                        {isHebrew ? 'לשאלה הבאה' : 'Next Question'}
                    </Button>
                </Box>
            </Box>
        );
    };
    const noQuestionReturn = () => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                background: `linear-gradient(135deg, ${theme.palette.background.paper} 30%, ${theme.palette.primary.light} 100%)`,
                borderRadius: '16px',
                boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
                padding: '40px',
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: theme.palette.primary.main, mb: 2 }}>
                {isHebrewOrArabic(languageLabel) ? 'אין שאלות זמינות' : 'No Questions Available'}
            </Typography>
            <Typography variant="body1" sx={{ color: theme.palette.text.secondary, mb: 4 }}>
                {isHebrewOrArabic(languageLabel)
                    ? 'נראה שאין שאלות זמינות כרגע עבור קורס זה. אנא נסה שוב מאוחר יותר או צור קשר עם התמיכה.'
                    : 'It looks like there are no questions available for this course at the moment. Please try again later or contact support.'}
            </Typography>
            <Button
                variant="contained"
                onClick={onBackToVideo}
                sx={{
                    background: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    padding: '10px 30px',
                    borderRadius: '30px',
                    textTransform: 'none',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    '&:hover': {
                        background: theme.palette.primary.dark,
                    },
                }}
            >
                {isHebrewOrArabic(languageLabel) ? 'חזור לוידאו' : 'Back to Video'}
            </Button>
        </Box>
    );
    const componentReturn = () => {
        return (
            <Box sx={{ margin: 'auto', mt: 4, width: '98%', paddingLeft: '50px', marginBlock: '3rem', marginLeft: '-1.5rem', overflowY: 'hidden', }} >
                <Box sx={{ position: 'fixed', top: 49, left: 0, width: '58.5%', display: 'flex', justifyContent: 'space-between', backgroundColor: theme.palette.background.paper, zIndex: 100, padding: 2 }}>
                    <Typography variant="h6" color="textPrimary" sx={{ margin: 'auto' }}>
                        {selectedQuestion ? selectedQuestion.topic : ''}
                    </Typography>
                </Box>

                <Box sx={{ position: 'fixed', top: 90, left: "2%", width: '56.5%', display: 'flex', justifyContent: 'space-between', backgroundColor: theme.palette.background.paper, zIndex: 100, padding: 2 }} ref={radioGroupRef}>
                    <FormControl component="fieldset">
                        <RadioGroup row
                            aria-label="quiz questions"
                            name="quiz-questions"
                            value={selectedQuestionIndex}
                            onChange={handleQuestionChange}
                            sx={{
                                //  display: 'grid',
                                //gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', // Ensures max 3 columns per row
                                gridAutoRows: '50px', // Fixed height for each row
                                maxHeight: '80px', // 3 rows max (3 * 50px)
                                overflowY: 'auto', // Scroll when exceeding row limit
                                //gap: 2, // Space between items
                                //padding: 1,
                                width: '100%',
                            }}>
                            {sortedAnswerData.map((item: any, index: any) => (
                                <Box
                                    key={index}
                                >
                                    <FormControlLabel
                                        key={index}
                                        value={index}
                                        control={<Radio />}
                                        label={index + 1}
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                textAlign: isHebrewOrArabic(item.topic) ? 'right' : 'left',
                                                fontWeight: selectedQuestionIndex === index ? 'bold' : 'normal',
                                            },
                                        }}
                                    />
                                </Box>
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box sx={{ marginTop: `${radioGroupHeight + 100}px` }}>
                    {multiChoiceOptions && scoreRender()}
                    {selectedQuestion && (
                        <Box sx={{ padding: '20px', marginLeft: '30px', borderRadius: '5px', backgroundColor: 'none' }}>
                            <Typography variant="h6" sx={{ marginBottom: 2, direction: isHebrewOrArabic(languageLabel) ? 'rtl' : 'ltr', textAlign: 'center' }}>
                                {`${languageLabel} ${selectedQuestionIndex + 1}`} {/* Display the number of the chosen question */}
                            </Typography>
                            <Box sx={{ marginTop: '30px', direction: isHebrewOrArabic(languageLabel) ? 'rtl' : 'ltr' }}>
                                {selectedQuestion.questionText ? selectedQuestion.questionText : <MainQuestionHtmlShow content={selectedQuestion.question} type="question" />}
                            </Box>
                            <Box sx={{}}>
                                {multiChoiceOptions &&
                                    renderMultipleChoiceOptions(
                                        multiChoiceOptions,
                                        correctAnswer,
                                        selectedOption,
                                        showFeedback,
                                        selectedQuestion
                                    )}
                            </Box>
                            <Box sx={{ marginBottom: '50px' }}>
                                {showAnswer && (
                                    <>
                                        <Divider sx={{ marginY: 2 }} />
                                        {
                                            <MainQuestionHtmlShow content={selectedQuestion.answer} type="answer" />
                                        }
                                    </>
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
                {/* <Box sx={{ position: 'fixed', bottom: 0, left: 0, width: '100%', display: 'flex', justifyContent: 'space-between', padding: '16px', backgroundColor: theme.palette.background.paper, zIndex: 2000 }}> */}
                <Box sx={{ position: 'fixed', bottom: 0, left: 0, width: '60%', display: 'flex', justifyContent: 'space-between', gap: 2, backgroundColor: theme.palette.background.paper, zIndex: 2000 }}>
                    {/* Left Section: Navigation */}
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Tooltip title="Previous Question">
                            <IconButton onClick={handlePreviousQuestion} disabled={selectedQuestionIndex === 0}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Next Question">
                            <IconButton onClick={handleNextQuestion} disabled={selectedQuestionIndex === sortedAnswerData.length - 1}>
                                <NavigateNextIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {/* Middle Section: Video Controls */}
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Tooltip title={showAnswer ? "Hide Answer" : "Show Answer"}>
                            <IconButton onClick={() => { handleShowAnswer(selectedQuestion) }}>
                                {showAnswer ? <UnpublishedIcon /> : < CheckCircleIcon />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={showMovie ? "Hide Movie" : "Show Movie"}>
                            <IconButton onClick={handleToggleMovie}>
                                {showMovie ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Back to Video">
                            <IconButton onClick={onBackToVideo}>
                                <VideoLibraryIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {/* Right Section: Reporting and Feedback */}
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Tooltip title="Solved">
                            <IconButton onClick={handleSolvedClick} sx={{ color: solvedColor }}>
                                <ThumbUpAltIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Didn't Solve">
                            <IconButton onClick={handleNotSolvedClick} sx={{ color: notSolvedColor }}>
                                <ThumbDownAltIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Report a Problem">
                            <IconButton onClick={handleReportClick} sx={{ color: reportColor }}>
                                <ReportProblemIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu anchorEl={solvedAnchorEl} open={Boolean(solvedAnchorEl)} onClose={handleSolvedClose}>
                            <MenuItem onClick={() => handleSolvedSelect('easy')}>Easy</MenuItem>
                            <MenuItem onClick={() => handleSolvedSelect('medium')}>Medium</MenuItem>
                            <MenuItem onClick={() => handleSolvedSelect('hard')}>Hard</MenuItem>
                        </Menu>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleReportClose}>
                            <MenuItem onClick={() => handleReportSelect("question isn't good")}>Question isn't good</MenuItem>
                            <MenuItem onClick={() => handleReportSelect('answer not good')}>Answer not good</MenuItem>
                            <MenuItem onClick={() => handleReportSelect('there is a typo')}>There is a typo</MenuItem>
                            <MenuItem onClick={() => handleReportSelect('there is no problem')}>There is no problem</MenuItem>
                        </Menu>
                    </Box>
                </Box>
                {
                    showMovie && (

                        <VideoBoxInteract index={videoDataState} onClose={handleToggleMovie} yBox={-50} xBox={1000} widthBox={500} heightBox={560} />

                    )
                }
            </Box >
        );
    }

    return (
        <>
            {loading ? loadingReturn() : sortedAnswerData.length === 0 ? noQuestionReturn() : componentReturn()}
        </>
    )
};

export default GeneralQuestionComponent;
